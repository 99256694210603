.hr {
    -webkit-tap-highlight-color: transparent;
    color: #fff;
    text-align: center;
    font: inherit;
    box-sizing: content-box;
    margin-top: 20px;
    margin-bottom: 20px;
    border: 0;
    height: 3px;
    background-image: linear-gradient(90deg, transparent, #fff, transparent);
}

p {
    font-weight: 300;
    font-style: normal;
    text-decoration: none;
    font-family: 'Montserrat';
    font-size: 23.0957px;
    line-height: 29.8886px;
    color: rgb(255, 255, 255);
    opacity: 0.8;
    text-align: center;
    margin: 0px;
    z-index: auto;
    width: auto;
    height: auto;
    padding: 0px;
    border-width: 0px;
}

.logo {
    width: 30%;
    max-width: 320px;
}

.event-img-container {
    margin: 35px 0 0 0;
    width: 100%;
    display: block;
    text-align: center;
}

.event {
    width: 85%;
    max-width: 700px;
}

.logo-container {
    width: 100%;
    display: block;
    text-align: center;
}

.texts-container {
    width: 100%;
    text-align: center;
}

.title-container {
    margin: auto;
    text-align: center;
    max-width: 800px;
}

@media only screen and (max-width: 767px) {
    .logo {
        width: 35%;
    }
}

/*
*/

.button-reserve-container {
    margin: 15px auto auto auto;
    text-align: center;
}

/* CSS */
.button-85 {
    width: 80%;
    max-width: 300px;
    padding: 0.6em 2em;
    border: none;
    outline: none;
    color: rgb(255, 255, 255);
    background: #111;
    cursor: pointer;
    position: relative;
    z-index: 0;
    border-radius: 10px;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    font-family: 'Montserrat';
}

.button-85:before {
    content: "";
    background: linear-gradient(45deg,
            #ff0000,
            #ff7300,
            #fffb00,
            #48ff00,
            #00ffd5,
            #002bff,
            #7a00ff,
            #ff00c8,
            #ff0000);
    position: absolute;
    top: -2px;
    left: -2px;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    -webkit-filter: blur(5px);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    animation: glowing-button-85 20s linear infinite;
    transition: opacity 0.3s ease-in-out;
    border-radius: 10px;
}

@keyframes glowing-button-85 {
    0% {
        background-position: 0 0;
    }

    50% {
        background-position: 400% 0;
    }

    100% {
        background-position: 0 0;
    }
}

.button-85:after {
    z-index: -1;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: #222;
    left: 0;
    top: 0;
    border-radius: 10px;
}

.footer {
    position: relative;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 2rem;
    color: white;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.2rem;
    font-family: 'Montserrat';
}




.footer-social-media {
    display: block;
}

.icon-ig {
    width: 20px;
}